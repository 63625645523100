import { Controller } from "@hotwired/stimulus";
import { useResize, useMatchMedia } from 'stimulus-use';

export default class extends Controller {
  connect() {
    this.initialHeight = this.getElementHeight();

    useMatchMedia(this, {
      mediaQueries: {
        app: '(max-width: 820px)',
        web: '(min-width: 821px)',
      }
    });

    this.initializeObserver();
  }

  resize({ height }) {
    const heightDifference = height - this.initialHeight;
    this.initialHeight = height;

    if (heightDifference > 0) {
      this.adjustScrollPosition(heightDifference);
    }
  }

  adjustScrollPosition(heightDifference) {
    window.scrollTo(0, window.scrollY + heightDifference);
  }

  getElementHeight() {
    return this.element.offsetHeight;
  }

  appChanged({ matches }) {
    if (matches) {
      this.initializeObserver();
    }
  }

  webChanged({ matches }) {
    if (matches) {
      this.unobserve();
    }
  }

  initializeObserver() {
    const [observe, unobserve] = useResize(this, { element: this.element });
    this.unobserve = unobserve;
  }
}

import { Controller } from "@hotwired/stimulus"
import { formatDate, formatTime, registerCursorTracker } from 'cleave-zen'

// Connects to data-controller="cleave"
// data-cleave-formatter-value="date"
// data-cleave-options-value='{ delimiter: ".", datePattern: ["d", "m", "Y"] }'
export default class extends Controller {
  static values = {
    options: Object,
    formatter: String,
  }

  connect() {
    console.debug("Connecting cleave controller ...")
    this._parseFromDataAttributes();
  }

  disconnect() {
    this.element.removeEventListener('input');
  }

  _parseFromDataAttributes() {
    let input = this.element;

    // Add a listener to the input to format the value
    input.addEventListener('input', (e) => {
      if (this.formatterValue === "date") {
        input.value = formatDate(e.target.value, this.optionsValue);
      } else if (this.formatterValue === "time") {
        input.value = formatTime(e.target.value, this.optionsValue);
      } else {
        console.error("Cleave: No valid formatter specified. Supported: date, time", this.formatterValue);
      }
    })

	// without a cursor tracker, you cannot use backspace in
	// a form input to delete values.
    registerCursorTracker({
      input: input,
      delimiter: this.optionsValue.delimiter
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import Swiper from 'swiper/bundle';


export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element, {
      // Optional parameters
      slidesPerView: "auto",
      spaceBetween: 12,
      centeredSlides: true,

      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 12,
          centeredSlides: false
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 24,
          centeredSlides: false
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 24,
          centeredSlides: false
        }
      }
    }




      // direction: 'horizontal',
      // loop: true,

      // // If we need pagination
      // pagination: {
      //   el: '.swiper-pagination',
      // },

      // // Navigation arrows
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },

      // // And if we need scrollbar
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      // },
    );
  }

  disconnect() {
    this.swiper.destroy();
  }
}

import { Controller } from "@hotwired/stimulus";

import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import ProgressBar from "@uppy/progress-bar";
import ThumbnailGenerator from "@uppy/thumbnail-generator";

const ImageCompressor = require("uppy-plugin-image-compressor");

import ActiveStorageUpload from "@puglet5/uppy-activestorage-upload";

export default class extends Controller {
  // static values = {
  //   fieldName: String,
  // };

  static targets = ["button", "progress", "container", "input", "deleted"];

  connect() {
    let direct_upload_url = document
      .querySelector("meta[name='direct-upload-url']")
      .getAttribute("content");

    this._uppy = new Uppy({
      id: "heroImageUpload",
      autoProceed: true,
      debug: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: null,
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".gif"],
      },
    });

    this._uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url,
    });

    this._uppy.use(FileInput, {
      id: "heroImageFileInput",
      target: this.buttonTarget,
    });

    this._uppy.use(ProgressBar, {
      id: "heroImageUploadProgressBar",
      target: this.progressTarget,
      hideAfterFinish: true,
    });

    this._uppy.use(ThumbnailGenerator, {
      thumbnailWidth: 768,
      waitForThumbnailsBeforeUpload: true,
    });

    this._uppy.use(ImageCompressor, {
      maxWidth: 1536,
      maxHeight: 1536,
    });

    this._uppy.on("thumbnail:generated", (file, preview) => {
      this.appendThumbnail(preview);
    });

    this._uppy.on("upload-success", (image, response) => {
      this.appendUploadedImage(image, response);
    });
  }

  disconnect() {
    this._uppy.destroy();
  }

  appendThumbnail(preview) {
    this.containerTarget.innerHTML = `
      <div class='hero-image-upload__preview'>
        <img src='${preview}' class='thumb'>
      </div>
    `;
    this.buttonTarget.classList.add("!hidden");
  }

  appendUploadedImage(file, response) {
    console.log(file);
    this.containerTarget.innerHTML = `
      <div class='hero-image-upload__preview' data-new-record="true">
        <button type="button" class='hero-image-upload__remove-button' data-action='hero-image-upload#removeUploadedImage' data-id='${file.id}'>
          <span class="material-symbols-outlined ms--icon">close</span>
        </button>
        <img id="myImage" src='${file.preview}' class='thumb'>
        <input type='hidden' name='${this.inputTarget.name}' value='${response.signed_id}' data-pending-upload='true'>
      </div>
    `;
    this.buttonTarget.classList.add("!hidden");
  }

  removeUploadedImage(event) {
    const id = event.target.closest("button").dataset.id;
    const wrapper = event.target.closest(".hero-image-upload__preview");

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";
      wrapper.querySelector("input[name*='_destroy']").value = "1";
      wrapper.classList.remove("hero-image-upload__preview");
      // this.deletedTarget.appendChild(wrapper);
    }
    this.buttonTarget.classList.remove("!hidden");

    if (typeof id !== undefined) this._uppy.removeFile(id);
  }
}

import TextareaAutogrow from "stimulus-textarea-autogrow";

export default class extends TextareaAutogrow {
  static values = {
    minHeight: { type: Number, default: 150 },
  }

  connect() {
    super.connect();

    // Bind the context of `this` to the autogrow method
    this.autogrow = this.autogrow.bind(this);

    this.element.addEventListener("focus", this.autogrow);
    // this.element.addEventListener("blur", this.autogrow);
  }

  disconnect() {
    super.disconnect();

    this.element.removeEventListener("focus", this.autogrow);
    // this.element.removeEventListener("blur", this.autogrow);
  }

  autogrow() {
    this.element.style.height = "24px"; // Force re-print before calculating the scrollHeight value.
    this.element.style.height = `${Math.max(this.element.scrollHeight, this.minHeightValue)}px`;
  }
}

import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["newComment"];

  connect() {

  }

  disconnect() {

  }

  newCommentTargetConnected(element) {
    element.scrollIntoView({ behavior: "smooth" });
    enter(element, "comment");
    // do not show enter animation for cached comments
    delete element.dataset.commentsTarget;
  }
}



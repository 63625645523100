import Timeago from '@stimulus-components/timeago'
import { de } from 'date-fns/locale'
import { differenceInDays, parseISO } from 'date-fns'

export default class extends Timeago {
  connect() {
    super.connect()
  }

  load() {
    if (this.isToday(this.datetimeValue)) return;
    super.load()
  }

  get locale() {
    return de
  }

  isToday(datetime) {
    const date = parseISO(datetime)
    return differenceInDays(new Date().setHours(23, 59, 59, 999), date) < 1
  }
}

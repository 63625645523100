import { Controller } from "@hotwired/stimulus";

var Masonry = require("masonry-layout");
var imagesLoaded = require('imagesloaded');

export default class extends Controller {
  connect() {
    this.msnry = new Masonry(this.element, {
      // options
      columnWidth: ".masonry-grid__sizer",
      gutter: ".masonry-grid__gutter-sizer",
      itemSelector: ".masonry-grid-item",
      percentPosition: true,
    });

    this.imgLoad = imagesLoaded(this.element, () => {
      // this.msnry.layout();
    });
    this.imgLoad.on( 'progress', ( instance, image ) => {
      this.msnry.layout();
    });
  }

  disconnect() {
    if (this.msnry) {
      this.msnry.destroy();
    }
  }
}

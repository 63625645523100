import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static targets = ["link"];

  connect() {
  }

  linkTargetConnected() {
    useIntersection(
      this, {
        element: this.linkTarget,
        rootMargin: "500px",
      }
    );
  }

  appear() {
    this.loadMore(this.nextUrl);
  }

  loadMore(url) {
    fetch(url,
      {
         method: "GET",
         headers: {
           "Accept": "text/vnd.turbo-stream.html, text/html, application/xhtml+xml"
         },
         credentials: "same-origin",
       }).then (response => response.text())
         .then(html => Turbo.renderStreamMessage(html));
  }

  get nextUrl() {
    return this.linkTarget.getAttribute("href");
  }
}

// fix viewport height issue on ios safari when showing/hiding keyboard
// https://github.com/element-hq/hydrogen-web/pull/279/files

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["commentBox"];

  connect() {
    this.initialViewportHeight = window.visualViewport.height;
    this.focusOutHandler = this.focusOutHandler.bind(this);
    this.commentBoxTarget.addEventListener('focusout', this.focusOutHandler);
  }

  disconnect() {
    this.commentBoxTarget.removeEventListener('focusout', this.focusOutHandler);
  }

  focusOutHandler() {
    this.element.style.setProperty('min-height', this.initialViewportHeight.toString() + 'px');
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  disconnect() {}

  clicked(event) {
    event.stopImmediatePropagation();
    this.dispatch("clicked");
  }
}

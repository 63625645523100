import { Controller } from "@hotwired/stimulus";

const bootstrap = require("bootstrap");

export default class extends Controller {
  static targets = ["accordion", "accordionItem", "images", "downloads", "video", "audio"];

  connect() {
    this.menu = new bootstrap.Collapse(this.accordionItemTarget, { toggle: false });
  }

  show(event) {
    event.preventDefault();

    const mediaAttachmentsType = event.target.dataset.mediaAttachmentsType;
    this[`${mediaAttachmentsType}Target`].classList.remove("hidden");
    this.accordionTarget.classList.add("hidden");
    this.menu.hide();
  }

  hide(event) {
    event.preventDefault();

    const card = event.target.closest(".card");

    card.classList.add("hidden");
    this.accordionTarget.classList.remove("hidden");

    const mediaAttachmentsType = card.dataset.mediaAttachmentsTarget;
    console.log(mediaAttachmentsType);
    this.dispatch(`${mediaAttachmentsType}-hidden`)
  }

  disconnect() {}
}

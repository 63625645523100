import { Controller } from "@hotwired/stimulus";
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ["pixel"];

  connect() {
    useIntersection(this, { element: this.pixelTarget, threshold: 1.0, visibleAttribute: "visible" });
  }

  appear() {
    this.element.classList.remove("scrolled");
  }

  disappear() {
    this.element.classList.add("scrolled");
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Hello, Stimulus!", this.element);
    console.log(this.element.closest("turbo-frame"));
    this.element.closest("turbo-frame")?.reload();
    // this.element.remove();
  }
}

import { Controller } from "@hotwired/stimulus";

import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import ProgressBar from "@uppy/progress-bar";
import ThumbnailGenerator from "@uppy/thumbnail-generator";

const ImageCompressor = require("uppy-plugin-image-compressor");

import ActiveStorageUpload from "@puglet5/uppy-activestorage-upload";

import {
  computePosition,
  shift,
  flip,
  offset,
  arrow,
  autoUpdate,
} from "@floating-ui/dom";

export default class extends Controller {
  static values = {
    fieldName: String,
  };

  static targets = ["button", "progress", "container", "template"];

  connect() {
    let direct_upload_url = document
      .querySelector("meta[name='direct-upload-url']")
      .getAttribute("content");

    this._uppy = new Uppy({
      id: "imagesUpload",
      autoProceed: true,
      debug: true,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: null,
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".gif"],
      },
    });

    this._uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url,
    });

    this._uppy.use(FileInput, {
      id: "imagesFileInput",
      target: this.buttonTarget,
    });

    this._uppy.use(ProgressBar, {
      id: "imagesUploadProgressBar",
      target: this.progressTarget,
      hideAfterFinish: true,
    });

    this._uppy.use(ThumbnailGenerator, {
      thumbnailHeight: 400,
      waitForThumbnailsBeforeUpload: true,
    });

    this._uppy.use(ImageCompressor, {
      maxWidth: 1280,
      maxHeight: 1280,
    });

    this._uppy.on("upload-success", (image, response) => {
      this.appendUploadedImage(image, response);
    });

    this._uppy.on("thumbnail:generated", (file, preview) => {
      this.appendThumbnail(file, preview);
    });

    this.element.getElementsByClassName('uppy-FileInput-input')[0].tabindex = -1;
    this.element.getElementsByClassName('uppy-FileInput-btn')[0].tabindex = -1;

    console.log('#######################################');
    console.log(this.element);
    console.log(this.element.getElementsByClassName('uppy-FileInput-input')[0]);
  }

  disconnect() {
    this._uppy.destroy();
  }

  appendThumbnail(file, preview) {
    // this.dispatch("hide-media-selector")

    let thumb = `
      <div class='image-attachments-uploader__attachment' data-id='${file.id}'>
        <div class='image-attachments-uploader__attachment-preview'>
          <img src='${preview}' class='thumb'>
        </div>
      </div>
    `;

    this.buttonTarget.insertAdjacentHTML("beforeBegin", thumb);
  }

  appendUploadedImage(file, response) {
    const clone = this.templateTarget.content.cloneNode(true);
    const timestamp = new Date().getTime().toString();

    clone.querySelector(".thumb").src = file.preview;

    clone.querySelectorAll("input").forEach((input) => {
      input.name = input.name.replace(/NEW_RECORD/g, timestamp);
      input.id = input.id.replace(/NEW_RECORD/g, timestamp);
    });

    clone.querySelectorAll("label").forEach((label) => {
      label.htmlFor = label.htmlFor.replace(/NEW_RECORD/g, timestamp);
    });

    clone.querySelector('input[data-signed-id="true"]').value =
      response.signed_id;
    clone.querySelector('[data-remove-button="true"]').dataset.id = file.id;

    this.containerTarget
      .querySelector('[data-id="' + file.id + '"]')
      .replaceWith(clone);

    // this.containerTarget.insertBefore(clone, this.buttonTarget);
  }

  removeUploadedImage(event) {
    event.preventDefault();

    const wrapper = event.target.closest(".image-attachments-uploader__attachment");
    const id = event.target.closest("button").dataset.id;

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";
      wrapper.querySelector("input[name*='_destroy']").value = "1";
    }

    if (typeof id !== undefined) this._uppy.removeFile(id);
  }

  editImage(event) {
    this.containerTarget.classList.add("editing");
    const image = event.target.closest(".image-attachments-uploader__attachment-preview");
    const form = image.nextElementSibling;
    const attachment = event.target.closest(".image-attachments-uploader__attachment");

    console.log(image, form, attachment);
    attachment.classList.add("active");
    form.classList.remove("hidden");

    this.handlePosition(image, form);
  }

  closeEditor(event) {
    this.containerTarget.classList.remove("editing");
    const form = event.target.closest(".image-attachments-uploader__attachment-form");
    const attachment = event.target.closest(".image-attachments-uploader__attachment");

    form.classList.add("hidden");
    attachment.classList.remove("active");
    this.cleanup;
  }

  handlePosition(image, form) {
    this.cleanup = autoUpdate(image, form, () =>
      this.updatePosition(image, form),
    );
  }

  updatePosition(image, form) {
    const arrowEl = form.querySelector(".arrow");

    computePosition(image, form, {
      middleware: [
        offset(6),
        flip(),
        shift({ padding: 12 }),
        arrow({ element: arrowEl }),
      ],
    }).then(({ x, y, middlewareData, placement }) => {
      Object.assign(form.style, { top: `${y}px`, left: `${x}px` });

      console.log("Placement:", placement);

      arrowEl.classList.add(placement);

      if (middlewareData.arrow) {
        const { x } = middlewareData.arrow;

        Object.assign(arrowEl.style, { left: `${x}px` });

        if (placement.includes("top")) {
          Object.assign(arrowEl.style, {
            top: "auto",
            bottom: `${-arrowEl.offsetHeight / 2}px`,
          });
        } else if (placement.includes("bottom")) {
          Object.assign(arrowEl.style, {
            bottom: "auto",
            top: `${-arrowEl.offsetHeight / 2}px`,
          });
        }
      }
    });
  }

  // activate(event) {
  //   console.log("Activate", event);

  //   this.element.classList.remove("hidden")

  //   const button = this.buttonTarget.getElementsByClassName("uppy-FileInput-btn")[0];
  //   console.log(button);
  //   const click_event = new CustomEvent('click', { bubbles: true });
  //   button.dispatchEvent(click_event);

  // }

  reset(event) {
    const images = this.containerTarget.querySelectorAll(".image-attachment")
    // console.log(this.containerTarget);
    // console.log(images);
    const previewImages = this.containerTarget.querySelectorAll(".image-attachment__preview")

    previewImages.forEach((image) => {
      image.remove();
    });

    images.forEach((image) => {
      if (image.dataset.newRecord === "true") {
        image.remove();
      } else {
        image.style.display = "none";
        image.querySelector("input[name*='_destroy']").value = "1";
      }
    });

    this._uppy.cancelAll()
  }


  // removeUploadedImage(event) {
  //   event.preventDefault();

  //   const wrapper = event.target.closest(".image-attachment");
  //   const id = event.target.closest("button").dataset.id;

  //   if (wrapper.dataset.newRecord === "true") {
  //     wrapper.remove();
  //   } else {
  //     wrapper.style.display = "none";
  //     wrapper.querySelector("input[name*='_destroy']").value = "1";
  //   }

  //   if (typeof id !== undefined) this._uppy.removeFile(id);
  // }
}

import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

const bootstrap = require("bootstrap");

export default class extends Controller {
  static values = {
    update_on_submit_success: Boolean
  }

  connect() {
    this.modal = new bootstrap.Modal(this.element, { backdrop: "static" });

    this.element.addEventListener("hidden.bs.modal", (event) => {
      this.#resetModalElement();
    });

    this.modal.show();

    this.turboFrame = this.element.closest("turbo-frame");
  }

  disconnect() {
    this.modal.dispose();
    this.#resetModalElement();
  }

  // refreshPage() {
  //   window.Turbo.visit(window.location.href, { action: "replace" });
  // }

  submitEnd(event) {
    const update_modal = (event.detail.formSubmission.submitter.name == 'update_modal');

    if (!update_modal && event.detail.success) {
      this.modal.hide();
    } else {
      this.element.scrollTo({ top: 0 });
      this.element.focus();
    }
  }

  // close(event) {
  //   if (event.type == 'submit') return this.modal.hide() // non-turbo forms / export modal

  //   const submit_value = event.detail.formSubmission.submitter.value

  //   if (event.detail.success && submit_value != 'Speichern & neu' && submit_value != 'Weiter') {
  //     this.modal.hide()
  //   }
  // }

  #resetModalElement() {
    this.turboFrame.removeAttribute("src");
    this.element.remove();
  }
}

Turbo.StreamActions.modal = function () {
  const message = this.getAttribute("message");

  if (message == "hide") window.modal?.hide();
  if (message == "close") window.modal?.hide();
};

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("form controller connected");

    this.element.addEventListener("turbo:submit-start", this.disable);
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-start", this.disable);
  }

  disable() {
    this.classList.add("loading");

    this.querySelectorAll("[type=submit]").forEach((e) => {
      e.disabled = true;
    });
  }
}

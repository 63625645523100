import { Controller } from "@hotwired/stimulus";

const bootstrap = require("bootstrap");

export default class extends Controller {
  connect() {
    this.offcanvas = new bootstrap.Offcanvas(this.element);
    // hide offcanvas on browser back button
    this.element.classList.remove("show");
  }

  disconnect() {
    this.offcanvas.dispose();
    // hide offcanvas on browser back button
    this.element.classList.remove("show");
  }

  show() {
    this.offcanvas.show();
  }

  hide() {
    this.offcanvas.hide();
  }
}

import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

const bootstrap = require("bootstrap");

Turbo.setConfirmMethod((message, element) => {
  let body = document.getElementById("confirmation-dialog__body");
  body.innerHTML = message; // TODO: maybe window.DOMPurify.sanitize(message)

  const trigger = new CustomEvent("show-confirmation");
  window.dispatchEvent(trigger);

  return new Promise((resolve, reject) => {
    window.addEventListener(
      "confirmation:confirmed",
      () => {
        resolve(true);
      },
      { once: true },
    );
  });
});

export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element, { backdrop: "static" });
  }

  disconnect() {
    this.modal.dispose();
  }

  show() {
    console.log(this.modal);
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  confirm() {
    this.hide();
    this.dispatch("confirmed");
  }
}

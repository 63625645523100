import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = true;
window.Stimulus = application;

export { application };

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

// Set up Turbo.StreamActions.modal function
Turbo.StreamActions.modal = function () {
  const message = this.getAttribute("message");
  if (message == "hide") window.modal?.hide();
  if (message == "close") window.modal?.hide();
};

// Escape modal from the backend on redirects
document.addEventListener("turbo:frame-missing", function (event) {
  if (
    event.detail.response.redirected &&
    event.target == document.querySelector("turbo-frame#modal")
  ) {
    event.preventDefault();
    event.detail.visit(event.detail.response);
  }
});

// document.addEventListener("turbo:before-prefetch", (event) => {
//   // if (isSavingData() || hasSlowInternet()) {
//   //   event.preventDefault()
//   // }
//   console.log(event.target);
// })

// let isModal = false;

// document.addEventListener("turbo:visit", (event) => {
//   console.log('###### turbo:visit');
//   console.log(event.detail.url);

//   if (event.detail.url == "http://localhost:3000/users/sign_in") {
//     isModal = true;

//     console.log('###### modal');
//   }
// })

// document.addEventListener("turbo:before-render", (event) => {
//   console.log('###### turbo:before-render');

//   if (isModal) {
//     event.preventDefault()
//   }

//   console.log(isModal);
// })

// document.addEventListener("turbo:before-render", async (event) => {
//   if (isModal == true) {
//     event.preventDefault()
//   }

//   // await animateOut()

//   // event.detail.resume()
// })

import { Controller } from "@hotwired/stimulus"
import { comment } from "postcss/lib/postcss"

export default class extends Controller {
  static targets = [ "placeholder" ]


  add(event) {
    event.preventDefault()
    let comment = event.target.closest(".comment")
    this.addQuote(comment)
  }

  remove(event) {
    event.preventDefault()
    this.placeholderTarget.innerHTML = "";
  }

  addQuote(comment) {
    let text = comment.dataset.quote;
    let id = comment.dataset.id;

    let quote = `
      <div class='comment-box__quote'>
        <input name='comment[parent_id]' value='${id}' type="hidden" />
        <div class='comment-box__quote__content'>
          ${text}
        </div>
        <button type="button" data-action="comment-reply#remove">
          <span class='material-symbols-outlined ms--icon'>close</span>
        </button>
      </div>
    `;

    this.placeholderTarget.innerHTML = quote;
  }
}


import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // this.element.textContent = "Hello World!";
  }

  activate(event) {
    // this.element.classList.add("hidden")
    event.preventDefault();
    this.dispatch("activate-image-attachments")
    this.element.classList.add("hidden")
  }

  hide(event) {
    this.element.classList.add("hidden")
  }
}

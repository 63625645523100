import { Controller } from "@hotwired/stimulus";
import { useResize, useMatchMedia } from 'stimulus-use';

export default class extends Controller {
  static targets = ["input", "button"];

  connect() {
    this.update = this.update.bind(this);
    this.inputTarget.addEventListener("input", this.update);
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.update);
  }

  update() {
    if (this.inputTarget.value.trim().length > 0) {
      this.buttonTarget.removeAttribute("disabled");
    } else {
      this.buttonTarget.setAttribute("disabled", "disabled");
    }
  }
}

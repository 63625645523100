import { Controller } from "@hotwired/stimulus";
import {
  computePosition,
  shift,
  flip,
  offset,
  autoUpdate,
} from "@floating-ui/dom";

const bootstrap = require("bootstrap");

export default class extends Controller {
  static targets = ["menu", "button"];

  connect() {
    this.dropdown = new bootstrap.Dropdown(this.buttonTarget, {
      display: "static",
    });

    this.element.addEventListener("shown.bs.dropdown", (event) => {
      this.handlePosition();
    });
  }

  disconnect() {
    this.cleanup;
    this.dropdown.dispose();
  }

  handlePosition() {
    this.cleanup = autoUpdate(this.buttonTarget, this.menuTarget, () =>
      this.updatePosition(),
    );
  }

  updatePosition() {
    computePosition(this.buttonTarget, this.menuTarget, {
      middleware: [offset(6), flip(), shift({ padding: 16 })],
    }).then(({ x, y }) => {
      Object.assign(this.menuTarget.style, { top: `${y}px`, left: `${x}px` });
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static targets = ["header"];

  connect() {
    this.calculateSafeAreaTop();
    this.initializeObserver();

    window.addEventListener("load", this.handleLoad.bind(this));
    window.addEventListener('orientationchange', this.handleOrientationChange.bind(this));
  }

  disconnect() {
    window.removeEventListener('orientationchange', this.handleOrientationChange.bind(this));
    window.removeEventListener("load", this.handleLoad.bind(this));

    if (this.observer) {
      this.observer.unobserve();
    }
  }

  handleOrientationChange() {
    this.calculateSafeAreaTop();
    this.initializeObserver();
  }

  handleLoad() {
    if (this.noneVisible()) {
      this.element.classList.add("header-stuck");
    }
  }

  calculateSafeAreaTop() {
    this.safeAreaTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat").replace('px', '')) || 0;
  }

  initializeObserver() {
    if (this.observer) {
      this.observer.unobserve();
    }

    const [observe, unobserve] = useIntersection(this, {
      element: this.headerTarget,
      threshold: 1,
      rootMargin: `-1px 0px 0px 0px`,
      visibleAttribute: "visible"
    });

    this.observer = { observe, unobserve };
    observe();
  }

  appear() {
    this.element.classList.remove("header-stuck");
  }

  disappear() {
    this.element.classList.add("header-stuck");
  }
}

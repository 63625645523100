import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["conditionalFields"];

  connect() {
    const input = this.element.querySelector('input[type="radio"]:checked');
    const value = input
      ? input.value
      : this.element.querySelector("select").value;

    this.showHide(value);
  }

  toggle(event) {
    event.stopImmediatePropagation();
    this.showHide(event.target.value);

    return false;
  }

  showHide(value) {
    this.conditionalFieldsTargets.forEach((target) => {
      const show = target.dataset.showIf.includes(value);
      target.classList.toggle("!hidden", !show);

      if (!show) {
        target.querySelector("select").selectedIndex = null;
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["sortableContainer"];

  static values = {
    url: String,
  };

  connect() {
    console.log("block-sortable#connect");

    this.initializeElementSorting(this);
  }

  enable() {
    this.sortable.option("disabled", false);
  }

  disable() {
    this.sortable.option("disabled", true);
  }

  initializeElementSorting(controller) {
    var sortableContainer = this.sortableContainerTarget;
    var self = this;

    this.sortable = Sortable.create(sortableContainer, {
      animation: 150,
      scroll: true,
      handle: ".block-editor__sort-handle",

      onEnd: function (evt) {
        var itemEl = evt.item;
        var url = self.urlValue;
        var position = evt.newIndex;
        var id = itemEl.dataset.id;

        patch(url, {
          headers: {
            Accept:
              "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
          },
          body: {
            id: id,
            position: position,
          },
        }).then((response) => {
          self.dispatch("success");
        });
      },
    });
  }
}

import { Controller } from "@hotwired/stimulus";

import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import ProgressBar from "@uppy/progress-bar";
import ThumbnailGenerator from "@uppy/thumbnail-generator";

const ImageCompressor = require("uppy-plugin-image-compressor");

import ActiveStorageUpload from "@puglet5/uppy-activestorage-upload";

export default class extends Controller {
  static values = {
    fieldName: String,
  };

  static targets = ["button", "progress", "container"];

  connect() {
    console.log("images_upload#connect");

    let direct_upload_url = document
      .querySelector("meta[name='direct-upload-url']")
      .getAttribute("content");

    this._uppy = new Uppy({
      id: "imagesUpload",
      autoProceed: true,
      debug: true,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: null,
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".gif"],
      },
    });

    this._uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url,
    });

    this._uppy.use(FileInput, {
      id: "imagesFileInput",
      target: this.buttonTarget,
    });

    this._uppy.use(ProgressBar, {
      id: "imagesUploadProgressBar",
      target: this.progressTarget,
      hideAfterFinish: true,
    });

    this._uppy.use(ThumbnailGenerator, {
      thumbnailHeight: 400,
      waitForThumbnailsBeforeUpload: true,
    });

    this._uppy.use(ImageCompressor, {
      maxWidth: 1280,
      maxHeight: 1280,
    });

    this._uppy.on("upload-success", (image, response) => {
      this.appendUploadedImage(image, response);
    });

    // this._uppy.on('file-added', (image, response) => {
    //   let count = Object.keys(this._uppy.store.state.files).length;

    //   if (count > 0) {
    //     this.sourceTarget.classList.remove('hide')
    //   }
    // })

    // this._uppy.on('file-removed', (image, response) => {
    //   let count = Object.keys(this._uppy.store.state.files).length;

    //   if (count == 0) {
    //     this.sourceTarget.classList.add('hide')
    //     this.sourceTarget.querySelector('input').value = null;
    //   }
    // })
  }

  disconnect() {
    this._uppy.destroy();
  }

  appendThumbnail(preview) {
    let thumb = `
      <div class='image-upload__preview'>
        <img src='${preview}' class='thumb'>
      </div>
    `;

    this.buttonTarget.insertAdjacentHTML("beforeBegin", thumb);
    this.buttonTarget.classList.add("!hidden");
  }

  appendUploadedImage(file, response) {
    console.log(file);
    let image = `
      <div class='images-upload__preview'>
        <button type="button" class='remove-btn' data-action='images-upload#removeUploadedImage' data-id='${file.id}'>
          <span class="material-symbols-outlined ms--icon">close</span>
        </button>
        <img src='${file.preview}' class='thumb'>
        <input type='hidden' name='${this.fieldNameValue}' value='${response.signed_id}' data-pending-upload='true'>
      </div>
    `;

    this.buttonTarget.insertAdjacentHTML("beforeBegin", image);
  }

  removeUploadedImage(event) {
    const id = event.target.closest("button").dataset.id;
    if (typeof id !== undefined) this._uppy.removeFile(id);
    event.target.closest(".images-upload__preview").remove();
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "selected"];

  connect() {
  }

  disconnect() {
  }

  selectedTargetConnected(element) {
    this.select();
  }

  select() {
    this.linkTarget.classList.add("!hidden")
    this.selectedTarget.classList.remove("!hidden")
  }

  unSelect() {
    this.linkTarget.classList.remove("!hidden")
    this.selectedTarget.remove()
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formControl"];

  static values = {
    hideOn: Boolean,
  };

  connect() {
    // const input = this.element.querySelector('input[type="radio"]:checked');
    // const value = input
    //   ? input.value
    //   : this.element.querySelector("select").value;

    // this.showHide(value);
  }

  toggle(event) {
    event.stopImmediatePropagation();
    this.showHide(event.target.checked == this.hideOnValue);

    return false;
  }

  showHide(shouldHide) {
    this.formControlTargets.forEach(formControl => {
      formControl.classList.toggle("!hidden", shouldHide);

      if (shouldHide) {
        const inputElement = formControl.querySelector('input');
        if (inputElement) {
          inputElement.value = "";
          // inputElement.checked = false;
        }
      }
    });
  }
}

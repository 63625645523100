import { Controller } from "@hotwired/stimulus";

import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import ProgressBar from "@uppy/progress-bar";

import ActiveStorageUpload from "@puglet5/uppy-activestorage-upload";
// import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";

export default class extends Controller {
  static values = {
    fieldName: String,
  };

  static targets = ["button", "progress", "container", "template"];

  connect() {
    let direct_upload_url = document
      .querySelector("meta[name='direct-upload-url']")
      .getAttribute("content");

    this._uppy = new Uppy({
      id: "filesUpload",
      autoProceed: true,
      debug: true,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: null,
        allowedFileTypes: [".pdf"],
      },
    });

    this._uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url,
    });

    this._uppy.use(FileInput, {
      id: "filesFileInput",
      target: this.buttonTarget,
    });

    this._uppy.use(ProgressBar, {
      id: "filesUploadProgressBar",
      target: this.progressTarget,
      hideAfterFinish: true,
    });

    this._uppy.on("upload-success", (file, response) => {
      this.appendUploadedfile(file, response);
    });

    this._uppy.on('file-added', (file) => {
      this.appendPreview(file);
    });
  }

  disconnect() {
    this._uppy.destroy();
  }

  appendPreview(file) {
    let preview = `
      <div class='file-attachments-uploader__attachment file-attachments-uploader__attachment--uploading' data-id='${file.id}'>
        <span class="ms--icon-text">
          <span class="material-symbols-outlined ms--icon">attach_file</span>
          <span class="ms--text">${file.name}</span>
        </span>
      </div>
    `;

    this.buttonTarget.insertAdjacentHTML("beforeBegin", preview);
  }

  appendUploadedfile(file, response) {
    const clone = this.templateTarget.content.cloneNode(true);
    const timestamp = new Date().getTime().toString();

    clone.querySelector(".file-name").innerHTML = file.name;
    // currently we cannot link to file because response.uploadURL is not available
    // clone.querySelector(".file-link").href = response.uploadURL;

    clone.querySelectorAll("input").forEach((input) => {
      input.name = input.name.replace(/NEW_RECORD/g, timestamp);
      input.id = input.id.replace(/NEW_RECORD/g, timestamp);
    });

    clone.querySelectorAll("label").forEach((label) => {
      label.htmlFor = label.htmlFor.replace(/NEW_RECORD/g, timestamp);
    });

    clone.querySelector('input[data-signed-id="true"]').value =
      response.signed_id;
    clone.querySelector('[data-remove-button="true"]').dataset.id = file.id;

    this.containerTarget
      .querySelector('[data-id="' + file.id + '"]')
      .replaceWith(clone);
  }

  removeUploadedFile(event) {
    event.preventDefault();

    const wrapper = event.target.closest(".file-attachments-uploader__attachment");
    const id = event.target.closest("button").dataset.id;

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";
      wrapper.querySelector("input[name*='_destroy']").value = "1";
    }

    if (typeof id !== undefined) this._uppy.removeFile(id);
  }

  // reset(event) {
  //   const files = this.containerTarget.querySelectorAll(".file-attachment")
  //   const previewfiles = this.containerTarget.querySelectorAll(".file-attachment__preview")

  //   previewfiles.forEach((file) => {
  //     file.remove();
  //   });

  //   files.forEach((file) => {
  //     if (file.dataset.newRecord === "true") {
  //       file.remove();
  //     } else {
  //       file.style.display = "none";
  //       file.querySelector("input[name*='_destroy']").value = "1";
  //     }
  //   });

  //   this._uppy.cancelAll()
  // }
}

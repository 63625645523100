import { Controller } from "@hotwired/stimulus";

import PhotoSwipeLightbox from "photoswipe/lightbox";

export default class extends Controller {
  connect() {
    const lightbox = new PhotoSwipeLightbox({
      // may select multiple "galleries"
      gallery: this.element,

      // Elements within gallery (slides)
      children: "a",

      // setup PhotoSwipe Core dynamic import
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();
  }
}

import { Controller } from "@hotwired/stimulus";
import scrollIntoView from "scroll-into-view-if-needed";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    console.log("block-editor#connect", this.element);
  }

  formTargetConnected(element) {
    this.element.classList.add("blocks-editor--busy");

    scrollIntoView(element.closest(".block-editor"), {
      behavior: "smooth",
      scrollMode: "if-needed",
    });

    this.dispatch("busy");
  }

  formTargetDisconnected(element) {
    this.element.classList.remove("blocks-editor--busy");
    this.dispatch("idle");
  }

  enable() {
    if (this.hasFormTarget) return;

    this.element.classList.remove("busy");
  }

  disable() {
    this.element.classList.add("busy");
  }

  cancel(event) {
    event.preventDefault();
    event.target.closest("turbo-frame").remove();
  }
}

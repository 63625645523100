import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener(
      "rhino-before-initialize",
      this.customizeRhinoEditor(),
    );
  }

  customizeRhinoEditor() {
    const rhinoEditor = document.querySelector("rhino-editor");

    if (rhinoEditor == null) return;

    rhinoEditor.starterKitOptions = {
      ...rhinoEditor.starterKitOptions,
      codeBlock: false,
      // blockquote: false,

      rhinoStrike: false,
      rhinoGallery: false,
      rhinoAttachment: false,
      rhinoFigcaption: false,
      rhinoImage: false,

      increaseIndentation: false,
      decreaseIndentation: false,
    };
  }
}
